import {
  ROLE_DEVELOPER,
  ROLE_ID,
  ROLE_MARKETER,
  ROLE_OPERATOR,
  ROLE_OWNER,
  ROLE_PROJECT_MANAGER
} from '@/constants/permission.const';

export const getRoleName = (roleName: string) => {
  switch (roleName) {
    case ROLE_MARKETER:
      return 'studio.member_manage.role_value_marketer';
    case ROLE_OPERATOR:
      return 'studio.member_manage.role_value_operator';
    case ROLE_DEVELOPER:
      return 'studio.member_manage.role_value_developer';
    case ROLE_PROJECT_MANAGER:
      return 'studio.member_manage.role_value_manager';
    case ROLE_OWNER:
      return 'studio.member_manage.role_value_owner';
    default:
      return roleName;
  }
};

export const getNameRoleAuthorityById = (roleName: string) => {
  switch (roleName) {
    case ROLE_ID.RELEASE_PLAN:
      return 'studio.gnb.common_2depth_plan';
    case ROLE_ID.PRODUCT_PAGE_MANAGEMENT:
      return 'studio.member_manage.role_manage_list_by_menu_prod_pg_mgmt';
    case ROLE_ID.PRODUCT_DATA_MANAGEMENT:
      return 'studio.member_manage.role_manage_list_by_menu_prod_data_mgmt';
    case ROLE_ID.PRODUCT_INGAME_MANAGEMENT:
      return 'studio.member_manage.role_manage_list_by_menu_ingame_store';
    case ROLE_ID.PRODUCT_MARKETING_MANAGEMENT:
      return 'studio.member_manage.role_manage_list_by_menu_prod_mkt_mgmt';
    case ROLE_ID.PRODUCT_SETTING:
      return 'studio.gnb.common_2depth_setting';
    case ROLE_ID.PRODUCT_STORE_PAGE:
      return 'studio.member_manage.role_manage_list_by_menu_prod_pg';
    case ROLE_ID.PRODUCT_SALE_CONDITION:
      return 'studio.member_manage.role_manage_list_by_menu_pur_cond';
    case ROLE_ID.PRODUCT_ALWAYS_ON_SALE_DISCOUNT:
      return 'studio.gnb.common_2depth_discount';
    case ROLE_ID.PRODUCT_ALWAYS_ON_SALE_PRICE:
      return 'studio.gnb.common_2depth_price';
    case ROLE_ID.PRODUCT_EARLY_ACCESS_TEXT_SETTING:
      return 'studio.gnb.common_2depth_early_access';
    case ROLE_ID.PRODUCT_BUILD_MANAGEMENT:
      return 'studio.gnb.common_2depth_build';
    case ROLE_ID.PRODUCT_RUN_OPTION:
      return 'studio.gnb.common_2depth_execution';
    case ROLE_ID.PRODUCT_RATING_BUILD_VERIFICATION:
      return 'studio.gnb.common_2depth_tier_review';
    case ROLE_ID.PRODUCT_TERMS:
      return 'studio.gnb.common_2depth_condition';
    case ROLE_ID.PRODUCT_STAT:
      return 'studio.gnb.common_2depth_stats';
    case ROLE_ID.PRODUCT_RANKING:
      return 'studio.gnb.common_2depth_ranking';
    case ROLE_ID.PRODUCT_ACHIEVEMENT:
      return 'studio.member_manage.role_manage_list_by_menu_challenge';
    case ROLE_ID.PRODUCT_CLOUD_SAVING:
      return 'studio.member_manage.role_manage_list_by_menu_cloud_saving';
    case ROLE_ID.PRODUCT_MATCH_MAKING:
      return 'studio.gnb.common_2depth_match';
    case ROLE_ID.INGAME_PURCHASE_SETTING:
      return 'studio.member_manage.role_manage_list_by_menu_ingame_setting';
    case ROLE_ID.INGAME_SHOP:
      return 'studio.member_manage.role_manage_list_by_menu_sub_ingame_store';
    case ROLE_ID.INGAME_PRODUCT:
      return 'studio.member_manage.role_manage_list_by_menu_ingame_store_product';
    case ROLE_ID.INGAME_DISCOUNT_PRODUCT:
      return 'studio.member_manage.role_manage_list_by_menu_ingame_store_discount';
    case ROLE_ID.INGAME_PURCHASE_HISTORY:
      return 'studio.member_manage.role_manage_list_by_menu_ingame_store_payment_history';
    case ROLE_ID.BANNER_IMAGE:
      return 'studio.gnb.common_2depth_banner';
    case ROLE_ID.PRODUCT_STATISTICS:
      return 'studio.member_manage.role_manage_list_by_menu_statistics';
    case ROLE_ID.PRODUCT_BILL_SETTLEMENT:
      return 'studio.member_manage.role_manage_list_by_menu_bill_settlement';
    default:
      return roleName;
  }
};
